<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">REPORTES</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >INFORME MENSUAL Y GESTI&Oacute;N</span
      >
    </li>
  </ul>
  <div
    class="surface-section px-4 py-5 md:px-12 lg:px-12"
    style="border: 1px solid #000000"
  >
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>INFORME POR MES Y GESTIÓN</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <span><strong>SELECCIONE SUCURSAL: </strong></span>
            <div class="p-inputgroup">
              <Dropdown
                :disabled="true"
                v-model="sucursalSelected"
                :options="sucursales"
                optionLabel="nombre"
                optionValue="id"
                placeholder="Seleccione una sucursal"
              />
            </div>
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>SELECCIONE MES: </strong></span>
            <div class="p-inputgroup">
              <Dropdown
                v-model="mes_seleccionado"
                :options="meses"
                optionLabel="label"
                placeholder="Seleccione un mes"
              />
            </div>
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>SELECCIONE AÑO: </strong></span>
            <div class="p-inputgroup">
              <InputNumber
                v-model="anio_gestion"
                :min="2021"
                :max="new Date().getFullYear()"
                :showButtons="true"
                mode="decimal"
                :useGrouping="false"
              />
            </div>
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>ACCIONES: </strong></span>
            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-info"
              v-tooltip.top="'Aplicar Filtros de Busqueda'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>

      <div class="p-fluid formgrid grid">
        <div class="field col-9">
          <div class="field col-12 md:col-12" v-if="semana1.length > 0">
            <table class="table">
              <thead>
                <tr>
                  <th
                    style="
                      background-color: #d3d3d3;
                      color: #000000;
                      font-weight: bold;
                      font-size: 14px;
                    "
                  >
                    SEMANA 1
                  </th>
                  <th
                    class="estilo_dias"
                    v-for="dia in dias_semana"
                    :key="dia.value"
                  >
                    {{ dia.dia }} <br />
                    <span
                      style="font-size: 9px; font-style: italic"
                      v-if="dia.value >= diaDeInicio"
                    >
                      {{ dia.value - diaDeInicio + 1 }} -
                      {{ mes_seleccionado.label.substring(0, 3) }}
                    </span>
                  </th>
                  <th style="background-color: #d3d3d3; color: #000000">
                    TOTAL
                  </th>
                </tr>
              </thead>
              <tbody v-if="semana1.length > 0">
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    INGRESOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_ventas || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{ "" }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana("total_ventas") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    COMISIONES (40%)
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_comisiones || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{ "" }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana("total_comisiones") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    ALMUERZO ADM.
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_almuerzos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{ "" }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana("total_almuerzos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    OTROS GASTOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_otros_gastos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{ "" }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana("total_otros_gastos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    SALDO
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td
                      style="
                        background-color: yellowgreen;
                        color: #000000;
                        font-weight: bold;
                      "
                      v-if="dia.value >= diaDeInicio"
                    >
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              parseFloat(
                                semana1.find(
                                  (item) =>
                                    new Date(item.fecha_venta).getDay() ===
                                    dia.value
                                ).total_ventas || 0
                              ) -
                                parseFloat(
                                  semana1.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_comisiones || 0
                                ) -
                                parseFloat(
                                  semana1.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_almuerzos || 0
                                ) -
                                parseFloat(
                                  semana1.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_otros_gastos || 0
                                )
                            )
                          : ""
                      }}
                    </td>
                    <td
                      style="
                        background-color: yellowgreen;
                        color: #000000;
                        font-weight: bold;
                      "
                      v-else
                    >
                      {{ "" }}
                    </td>
                  </template>
                  <td>
                    {{ 0 }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td style="background-color: yellow; color: #000000">
                    CANT. SERVICIOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_sercios_vendidos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{ "" }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana("total_sercios_vendidos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: steelblue; color: #000000">
                    TOTAL LITROS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_litros || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{ "" }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana("total_litros") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: skyblue; color: #000000">
                    LITROS / VEHÍCULOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).litros_por_vehiculo || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{ "" }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana("litros_por_vehiculo") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="field col-12 md:col-12" v-if="semana2.length > 0">
            <table class="table">
              <thead>
                <tr>
                  <th
                    style="
                      background-color: #d3d3d3;
                      color: #000000;
                      font-weight: bold;
                      font-size: 14px;
                    "
                  >
                    SEMANA 2
                  </th>
                  <th
                    class="estilo_dias"
                    v-for="dia in dias_semana"
                    :key="dia.value"
                  >
                    {{ dia.dia }} <br />
                    <span style="font-size: 9px; font-style: italic">
                      {{ diaSegundaSemana + dia.value }} -
                      {{ mes_seleccionado.label.substring(0, 3) }}
                    </span>
                  </th>
                  <th style="background-color: #d3d3d3; color: #000000">
                    TOTAL
                  </th>
                </tr>
              </thead>
              <tbody v-if="semana2.length > 0">
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    INGRESOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_ventas || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_ventas || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana2("total_ventas") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    COMISIONES (40%)
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_comisiones || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_comisiones || 0
                            )
                          : ""
                      }}
                    </td></template
                  >

                  <td>
                    {{ calcularTotalSemana2("total_comisiones") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    ALMUERZO ADM.
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_almuerzos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_almuerzos || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana2("total_almuerzos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    OTROS GASTOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_otros_gastos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_otros_gastos || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana2("total_otros_gastos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    SALDO
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td
                      style="
                        background-color: yellowgreen;
                        color: #000000;
                        font-weight: bold;
                      "
                      v-if="dia.value >= diaDeInicio"
                    >
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              parseFloat(
                                semana2.find(
                                  (item) =>
                                    new Date(item.fecha_venta).getDay() ===
                                    dia.value
                                ).total_ventas || 0
                              ) -
                                parseFloat(
                                  semana2.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_comisiones || 0
                                ) -
                                parseFloat(
                                  semana2.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_almuerzos || 0
                                ) -
                                parseFloat(
                                  semana2.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_otros_gastos || 0
                                )
                            )
                          : ""
                      }}
                    </td>
                    <td
                      style="
                        background-color: yellowgreen;
                        color: #000000;
                        font-weight: bold;
                      "
                      v-else
                    >
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              parseFloat(
                                semana1.find(
                                  (item) =>
                                    new Date(item.fecha_venta).getDay() ===
                                    dia.value
                                ).total_ventas || 0
                              ) -
                                parseFloat(
                                  semana1.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_comisiones || 0
                                ) -
                                parseFloat(
                                  semana1.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_almuerzos || 0
                                ) -
                                parseFloat(
                                  semana1.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_otros_gastos || 0
                                )
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ convertirNumeroGermanicFormat(0) }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td style="background-color: yellow; color: #000000">
                    CANT. SERVICIOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_sercios_vendidos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_sercios_vendidos || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana2("total_sercios_vendidos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: steelblue; color: #000000">
                    TOTAL LITROS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_litros || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_litros || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana2("total_litros") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: skyblue; color: #000000">
                    LITROS / VEHÍCULOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).litros_por_vehiculo || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana1.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana1.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).litros_por_vehiculo || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana2("litros_por_vehiculo") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="field col-12 md:col-12" v-if="semana3.length > 0">
            <table class="table">
              <thead>
                <tr>
                  <th
                    style="
                      background-color: #d3d3d3;
                      color: #000000;
                      font-weight: bold;
                      font-size: 14px;
                    "
                  >
                    SEMANA 3
                  </th>
                  <th
                    class="estilo_dias"
                    v-for="dia in dias_semana"
                    :key="dia.value"
                  >
                    {{ dia.dia }} <br />
                    <span style="font-size: 9px; font-style: italic">
                      {{ diaTerceraSemana + dia.value }} -
                      {{ mes_seleccionado.label.substring(0, 3) }}
                    </span>
                  </th>
                  <th style="background-color: #d3d3d3; color: #000000">
                    TOTAL
                  </th>
                </tr>
              </thead>
              <tbody v-if="semana3.length > 0">
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    INGRESOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_ventas || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_ventas || 0
                            )
                          : ""
                      }}
                    </td></template
                  >

                  <td>
                    {{ calcularTotalSemana3("total_ventas") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    COMISIONES (40%)
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_comisiones || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_comisiones || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana3("total_comisiones") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    ALMUERZO ADM.
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_almuerzos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_almuerzos || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana3("total_almuerzos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    OTROS GASTOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_otros_gastos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_otros_gastos || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana3("total_otros_gastos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    SALDO
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td
                      style="
                        background-color: yellowgreen;
                        color: #000000;
                        font-weight: bold;
                      "
                      v-if="dia.value >= diaDeInicio"
                    >
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              parseFloat(
                                semana3.find(
                                  (item) =>
                                    new Date(item.fecha_venta).getDay() ===
                                    dia.value
                                ).total_ventas || 0
                              ) -
                                parseFloat(
                                  semana3.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_comisiones || 0
                                ) -
                                parseFloat(
                                  semana3.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_almuerzos || 0
                                ) -
                                parseFloat(
                                  semana3.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_otros_gastos || 0
                                )
                            )
                          : ""
                      }}
                    </td>
                    <td
                      style="
                        background-color: yellowgreen;
                        color: #000000;
                        font-weight: bold;
                      "
                      v-else
                    >
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              parseFloat(
                                semana2.find(
                                  (item) =>
                                    new Date(item.fecha_venta).getDay() ===
                                    dia.value
                                ).total_ventas || 0
                              ) -
                                parseFloat(
                                  semana2.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_comisiones || 0
                                ) -
                                parseFloat(
                                  semana2.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_almuerzos || 0
                                ) -
                                parseFloat(
                                  semana2.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_otros_gastos || 0
                                )
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ convertirNumeroGermanicFormat(0) }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td style="background-color: yellow; color: #000000">
                    CANT. SERVICIOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_sercios_vendidos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_sercios_vendidos || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana3("total_sercios_vendidos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: steelblue; color: #000000">
                    TOTAL LITROS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_litros || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_litros || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana3("total_litros") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: skyblue; color: #000000">
                    LITROS / VEHÍCULOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).litros_por_vehiculo || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana2.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana2.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).litros_por_vehiculo || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana3("litros_por_vehiculo") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="field col-12 md:col-12" v-if="semana4.length > 0">
            <table class="table">
              <thead>
                <tr>
                  <th
                    style="
                      background-color: #d3d3d3;
                      color: #000000;
                      font-weight: bold;
                      font-size: 14px;
                    "
                  >
                    SEMANA 4
                  </th>
                  <th
                    class="estilo_dias"
                    v-for="dia in dias_semana"
                    :key="dia.value"
                  >
                    {{ dia.dia }} <br />
                    <span style="font-size: 9px; font-style: italic">
                      {{ diaCuartaSemana + dia.value }} -
                      {{ mes_seleccionado.label.substring(0, 3) }}
                    </span>
                  </th>
                  <th style="background-color: #d3d3d3; color: #000000">
                    TOTAL
                  </th>
                </tr>
              </thead>
              <tbody v-if="semana4.length > 0">
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    INGRESOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_ventas || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_ventas || 0
                            )
                          : ""
                      }}
                    </td></template
                  >

                  <td>
                    {{ calcularTotalSemana4("total_ventas") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    COMISIONES (40%)
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_comisiones || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_comisiones || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana4("total_comisiones") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    ALMUERZO ADM.
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_almuerzos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_almuerzos || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana4("total_almuerzos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    OTROS GASTOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_otros_gastos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_otros_gastos || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana4("total_otros_gastos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    SALDO
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td
                      style="
                        background-color: yellowgreen;
                        color: #000000;
                        font-weight: bold;
                      "
                      v-if="dia.value >= diaDeInicio"
                    >
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              parseFloat(
                                semana4.find(
                                  (item) =>
                                    new Date(item.fecha_venta).getDay() ===
                                    dia.value
                                ).total_ventas || 0
                              ) -
                                parseFloat(
                                  semana4.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_comisiones || 0
                                ) -
                                parseFloat(
                                  semana4.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_almuerzos || 0
                                ) -
                                parseFloat(
                                  semana4.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_otros_gastos || 0
                                )
                            )
                          : ""
                      }}
                    </td>
                    <td
                      style="
                        background-color: yellowgreen;
                        color: #000000;
                        font-weight: bold;
                      "
                      v-else
                    >
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              parseFloat(
                                semana3.find(
                                  (item) =>
                                    new Date(item.fecha_venta).getDay() ===
                                    dia.value
                                ).total_ventas || 0
                              ) -
                                parseFloat(
                                  semana3.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_comisiones || 0
                                ) -
                                parseFloat(
                                  semana3.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_almuerzos || 0
                                ) -
                                parseFloat(
                                  semana3.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_otros_gastos || 0
                                )
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ convertirNumeroGermanicFormat(0) }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td style="background-color: yellow; color: #000000">
                    CANT. SERVICIOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_sercios_vendidos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_sercios_vendidos || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana4("total_sercios_vendidos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: steelblue; color: #000000">
                    TOTAL LITROS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_litros || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_litros || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana4("total_litros") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: skyblue; color: #000000">
                    LITROS / VEHÍCULOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).litros_por_vehiculo || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana3.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana3.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).litros_por_vehiculo || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana4("litros_por_vehiculo") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="field col-12 md:col-12" v-if="semana5.length > 0">
            <table class="table">
              <thead>
                <tr>
                  <th
                    style="
                      background-color: #d3d3d3;
                      color: #000000;
                      font-weight: bold;
                      font-size: 14px;
                    "
                  >
                    SEMANA 5
                  </th>
                  <th
                    class="estilo_dias"
                    v-for="dia in dias_semana"
                    :key="dia.value"
                  >
                    {{ dia.dia }} <br />
                    <span style="font-size: 9px; font-style: italic">
                      {{ diaQuintaSemana + dia.value }} -
                      {{ mes_seleccionado.label.substring(0, 3) }}
                    </span>
                  </th>
                  <th style="background-color: #d3d3d3; color: #000000">
                    TOTAL
                  </th>
                </tr>
              </thead>
              <tbody v-if="semana5.length > 0">
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    INGRESOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana5.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana5.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_ventas || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_ventas || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana5("total_ventas") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    COMISIONES (40%)
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana5.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana5.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_comisiones || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_comisiones || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana5("total_comisiones") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    ALMUERZO ADM.
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana5.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana5.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_almuerzos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_almuerzos || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana5("total_almuerzos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    OTROS GASTOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana5.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana5.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_otros_gastos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_otros_gastos || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana5("total_otros_gastos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    SALDO
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td
                      style="
                        background-color: yellowgreen;
                        color: #000000;
                        font-weight: bold;
                      "
                      v-if="dia.value >= diaDeInicio"
                    >
                      {{
                        semana5.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              parseFloat(
                                semana5.find(
                                  (item) =>
                                    new Date(item.fecha_venta).getDay() ===
                                    dia.value
                                ).total_ventas || 0
                              ) -
                                parseFloat(
                                  semana5.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_comisiones || 0
                                ) -
                                parseFloat(
                                  semana5.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_almuerzos || 0
                                ) -
                                parseFloat(
                                  semana5.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_otros_gastos || 0
                                )
                            )
                          : ""
                      }}
                    </td>
                    <td
                      style="
                        background-color: yellowgreen;
                        color: #000000;
                        font-weight: bold;
                      "
                      v-else
                    >
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              parseFloat(
                                semana4.find(
                                  (item) =>
                                    new Date(item.fecha_venta).getDay() ===
                                    dia.value
                                ).total_ventas || 0
                              ) -
                                parseFloat(
                                  semana4.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_comisiones || 0
                                ) -
                                parseFloat(
                                  semana4.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_almuerzos || 0
                                ) -
                                parseFloat(
                                  semana4.find(
                                    (item) =>
                                      new Date(item.fecha_venta).getDay() ===
                                      dia.value
                                  ).total_otros_gastos || 0
                                )
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ convertirNumeroGermanicFormat(0) }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td style="background-color: yellow; color: #000000">
                    CANT. SERVICIOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana5.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana5.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_sercios_vendidos || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_sercios_vendidos || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana5("total_sercios_vendidos") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: steelblue; color: #000000">
                    TOTAL LITROS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana5.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana5.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_litros || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).total_litros || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana5("total_litros") }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: skyblue; color: #000000">
                    LITROS / VEHÍCULOS
                  </td>
                  <template v-for="dia in dias_semana" :key="dia.value">
                    <td v-if="dia.value >= diaDeInicio">
                      {{
                        semana5.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana5.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).litros_por_vehiculo || 0
                            )
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        semana4.find(
                          (item) =>
                            new Date(item.fecha_venta).getDay() === dia.value
                        )
                          ? convertirNumeroGermanicFormat(
                              semana4.find(
                                (item) =>
                                  new Date(item.fecha_venta).getDay() ===
                                  dia.value
                              ).litros_por_vehiculo || 0
                            )
                          : ""
                      }}
                    </td>
                  </template>
                  <td>
                    {{ calcularTotalSemana5("litros_por_vehiculo") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="field col-3" v-if="semana1.length > 0">
          <div class="field col-12 md:col-12">
            <table class="table">
              <thead>
                <tr>
                  <th
                    style="
                      background-color: #d3d3d3;
                      color: #000000;
                      font-weight: bold;
                      font-size: 14px;
                    "
                  >
                    CAJA {{ (mes_seleccionado || {}).label }}
                  </th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    TOTAL INGRESOS
                  </td>
                  <td>
                    {{ convertirNumeroGermanicFormat(total_ingresos_mes) }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    TOTAL COMISIONES (40%)
                  </td>
                  <td>
                    {{ convertirNumeroGermanicFormat(total_comisiones_mes) }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    TOTAL ALMUERZO ADM.
                  </td>
                  <td>
                    {{ convertirNumeroGermanicFormat(total_almuerzos_mes) }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: #d3d3d3; color: #000000">
                    TOTAL OTROS GASTOS
                  </td>
                  <td>
                    {{ convertirNumeroGermanicFormat(total_otros_gastos_mes) }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td style="background-color: yellow; color: #000000">
                    CANT. SERVICIOS DEL MES
                  </td>
                  <td>
                    {{
                      convertirNumeroGermanicFormat(total_sercios_vendidos_mes)
                    }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: steelblue; color: #000000">
                    TOTAL LITROS DEL MES
                  </td>
                  <td>
                    {{ convertirNumeroGermanicFormat(total_litros_mes) }}
                  </td>
                </tr>
                <tr>
                  <td style="background-color: skyblue; color: #000000">
                    LITROS / VEHÍCULOS
                  </td>
                  <td>
                    {{
                      convertirNumeroGermanicFormat(
                        total_litros_por_vehiculo_mes
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="field col-12 md:col-12">
            <p style="text-align: right; font-size: 14px">
              <strong>TOTAL EFECTIVO:</strong>
              <span
                style="
                  background-color: yellow;
                  padding: 2px;
                  border-radius: 5px;
                  border: 1px solid black;
                  padding-left: 5px;
                  padding-right: 5px;
                "
                >{{ convertirNumeroGermanicFormat(total_efectivo_mes) }}</span
              >
            </p>
            <p style="text-align: right; font-size: 14px">
              <strong>TOTAL QR:</strong>
              <span
                style="
                  background-color: aquamarine;
                  padding: 2px;
                  border-radius: 5px;
                  border: 1px solid black;
                  padding-left: 5px;
                  padding-right: 5px;
                "
                >{{
                  convertirNumeroGermanicFormat(total_transferencias_mes)
                }}</span
              >
            </p>
          </div>
          <div class="field col-12 md:col-12">
            <table class="table" style="font-size: 14px; font-weight: bold">
              <thead>
                <tr>
                  <td>CANT. DÍAS LAB.</td>
                  <td>
                    <InputNumber
                      v-model="cant_dias_laborales"
                      :min="0"
                      :max="100"
                      :useGrouping="false"
                      :inputClass="'text-center'"
                    />
                  </td>
                </tr>
                <tr>
                  <td>SERVICIOS / DÍA:</td>
                  <td>
                    {{
                      convertirNumeroGermanicFormat(
                        total_sercios_vendidos_mes / cant_dias_laborales ?? 0
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td>L. / VEHÍCULO</td>
                  <td>
                    {{
                      convertirNumeroGermanicFormat(
                        total_litros_mes / total_sercios_vendidos_mes ?? 0
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td>ING/VEHÍCULO:</td>
                  <td>
                    {{
                      convertirNumeroGermanicFormat(
                        total_ingresos_mes / total_sercios_vendidos_mes ?? 0
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td>SALDO PRELIM. / VEHÍCULO:</td>
                  <td>
                    {{
                      convertirNumeroGermanicFormat(
                        (total_ingresos_mes -
                          total_comisiones_mes -
                          total_almuerzos_mes -
                          total_otros_gastos_mes) /
                          cant_dias_laborales
                      )
                    }}
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VentaService from "@/service/VentaService";
import SucursalService from "@/service/SucursalService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      enviando: false,
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      meses: [
        { label: "ENERO", value: 1 },
        { label: "FEBRERO", value: 2 },
        { label: "MARZO", value: 3 },
        { label: "ABRIL", value: 4 },
        { label: "MAYO", value: 5 },
        { label: "JUNIO", value: 6 },
        { label: "JULIO", value: 7 },
        { label: "AGOSTO", value: 8 },
        { label: "SEPTIEMBRE", value: 9 },
        { label: "OCTUBRE", value: 10 },
        { label: "NOVIEMBRE", value: 11 },
        { label: "DICIEMBRE", value: 12 },
      ],
      mes_seleccionado: null,
      anio_gestion: new Date().getFullYear(),
      mostrarModal: false,
      listadoMensualGestion: [],
      semana1: [],
      semana2: [],
      semana3: [],
      semana4: [],
      semana5: [],
      dias_semana: [
        { dia: "LUNES", value: 0 },
        { dia: "MARTES", value: 1 },
        { dia: "MIERCOLES", value: 2 },
        { dia: "JUEVES", value: 3 },
        { dia: "VIERNES", value: 4 },
        { dia: "SABADO", value: 5 },
        { dia: "DOMINGO", value: 6 },
      ],
      diaDeInicio: 0,
      diaSegundaSemana: 0,
      diaTerceraSemana: 0,
      diaCuartaSemana: 0,
      diaQuintaSemana: 0,
      total_ingresos_mes: 0,
      total_comisiones_mes: 0,
      total_almuerzos_mes: 0,
      total_otros_gastos_mes: 0,
      total_sercios_vendidos_mes: 0,
      total_litros_mes: 0,
      total_litros_por_vehiculo_mes: 0,
      total_efectivo_mes: 0,
      total_transferencias_mes: 0,
      saldo_semana1: 0,
      saldo_semana2: 0,
      cant_dias_laborales: 0,
    };
  },
  ventaService: null,
  sucursalService: null,
  auth: null,
  created() {
    this.ventaService = new VentaService();
    this.sucursalService = new SucursalService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarSucursales();
    this.mes_seleccionado = this.meses.find(
      (mes) => mes.value === new Date().getMonth() + 1
    );
  },
  computed: {
    total_saldo_semana1() {
      let total = 0;
      this.semana1.forEach((item) => {
        total += parseFloat(item.total_ventas);
      });
      return this.convertirNumeroGermanicFormat(total);
    },
  },
  methods: {
    obtenerDiaDeInicio(mes_seleccionado) {
      let fecha = new Date(this.anio_gestion, mes_seleccionado.value - 1, 1);
      let diaDeLaSemana = fecha.getDay();
      this.diaDeInicio = (diaDeLaSemana + 6) % 7;
      this.diaSegundaSemana = 8 - this.diaDeInicio;
      this.diaTerceraSemana = 15 - this.diaDeInicio;
      this.diaCuartaSemana = 22 - this.diaDeInicio;
      this.diaQuintaSemana = 29 - this.diaDeInicio;

      return (
        this.diaDeInicio,
        this.diaSegundaSemana,
        this.diaTerceraSemana,
        this.diaCuartaSemana,
        this.diaQuintaSemana
      );
    },
    calcularTotalSemana(campo) {
      let total = 0;
      if (campo === "total_ventas") {
        this.semana1.forEach((item) => {
          total += parseFloat(item.total_ventas);
        });
      } else if (campo === "total_comisiones") {
        this.semana1.forEach((item) => {
          total += parseFloat(item.total_comisiones || 0);
        });
      } else if (campo === "total_almuerzos") {
        this.semana1.forEach((item) => {
          total += parseFloat(item.total_almuerzos || 0);
        });
      } else if (campo === "total_otros_gastos") {
        this.semana1.forEach((item) => {
          total += parseFloat(item.total_otros_gastos || 0);
        });
      }
      return this.convertirNumeroGermanicFormat(total);
    },
    calcularTotalSemana2(campo) {
      let total = 0;
      if (campo === "total_ventas") {
        this.semana2.forEach((item) => {
          total += parseFloat(item.total_ventas);
        });
      } else if (campo === "total_comisiones") {
        this.semana2.forEach((item) => {
          total += parseFloat(item.total_comisiones || 0);
        });
      } else if (campo === "total_almuerzos") {
        this.semana2.forEach((item) => {
          total += parseFloat(item.total_almuerzos || 0);
        });
      } else if (campo === "total_otros_gastos") {
        this.semana2.forEach((item) => {
          total += parseFloat(item.total_otros_gastos || 0);
        });
      }
      return this.convertirNumeroGermanicFormat(total);
    },
    calcularTotalSemana3(campo) {
      let total = 0;
      if (campo === "total_ventas") {
        this.semana3.forEach((item) => {
          total += parseFloat(item.total_ventas);
        });
      } else if (campo === "total_comisiones") {
        this.semana3.forEach((item) => {
          total += parseFloat(item.total_comisiones || 0);
        });
      } else if (campo === "total_almuerzos") {
        this.semana3.forEach((item) => {
          total += parseFloat(item.total_almuerzos || 0);
        });
      } else if (campo === "total_otros_gastos") {
        this.semana3.forEach((item) => {
          total += parseFloat(item.total_otros_gastos || 0);
        });
      }
      return this.convertirNumeroGermanicFormat(total);
    },
    calcularTotalSemana4(campo) {
      let total = 0;
      if (campo === "total_ventas") {
        this.semana4.forEach((item) => {
          total += parseFloat(item.total_ventas);
        });
      } else if (campo === "total_comisiones") {
        this.semana4.forEach((item) => {
          total += parseFloat(item.total_comisiones || 0);
        });
      } else if (campo === "total_almuerzos") {
        this.semana4.forEach((item) => {
          total += parseFloat(item.total_almuerzos || 0);
        });
      } else if (campo === "total_otros_gastos") {
        this.semana4.forEach((item) => {
          total += parseFloat(item.total_otros_gastos || 0);
        });
      }
      return this.convertirNumeroGermanicFormat(total);
    },
    calcularTotalSemana5(campo) {
      let total = 0;
      if (campo === "total_ventas") {
        this.semana5.forEach((item) => {
          total += parseFloat(item.total_ventas);
        });
      } else if (campo === "total_comisiones") {
        this.semana5.forEach((item) => {
          total += parseFloat(item.total_comisiones || 0);
        });
      } else if (campo === "total_almuerzos") {
        this.semana5.forEach((item) => {
          total += parseFloat(item.total_almuerzos || 0);
        });
      } else if (campo === "total_otros_gastos") {
        this.semana5.forEach((item) => {
          total += parseFloat(item.total_otros_gastos || 0);
        });
      }
      return this.convertirNumeroGermanicFormat(total);
    },
    BuscarFiltro() {
      this.listadoMensualGestion = [];
      this.enviando = true;
      this.cargarReporteMensualGestion();
      this.obtenerDiaDeInicio(this.mes_seleccionado);
    },
    cargarReporteMensualGestion() {
      let datos = {
        sucursal_id: this.sucursalSelected,
        anio_gestion: this.anio_gestion,
        mes_seleccionado: this.mes_seleccionado.value,
      };
      this.ventaService
        .filtrarReporteMensualGestion(datos)
        .then((data) => {
          this.listadoMensualGestion = data.listadoMensualGestion ?? [];
          this.semana1 = data.semana1 ?? [];
          this.semana2 = data.semana2 ?? [];
          this.semana3 = data.semana3 ?? [];
          this.semana4 = data.semana4 ?? [];
          this.semana5 = data.semana5 ?? [];
          this.total_ingresos_mes = data.total_ingresos_mes ?? 0;
          this.total_comisiones_mes = data.total_comisiones_mes ?? 0;
          this.total_almuerzos_mes = data.total_almuerzos_mes ?? 0;
          this.total_otros_gastos_mes = data.total_otros_gastos_mes ?? 0;
          this.total_sercios_vendidos_mes =
            data.total_sercios_vendidos_mes ?? 0;
          this.total_litros_mes = data.total_litros_mes ?? 0;
          this.total_litros_por_vehiculo_mes =
            data.total_litros_por_vehiculo_mes ?? 0;
          this.total_efectivo_mes = data.total_efectivo_mes ?? 0;
          this.total_transferencias_mes = data.total_transferencias_mes ?? 0;
          this.cant_dias_laborales = data.listadoMensualGestion.length;

          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((data) => {
          data.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-fondo {
  background-color: #7bffae;
  border: 1px solid #000000;
  border-bottom: 2px solid #000000;

  .p-inputgroup {
    width: 100%;
  }

  .p-inputgroup .p-inputtext {
    width: 100%;
  }
}

.style_card {
  border: 1px solid #000000;
  border-bottom: 5px solid #000000;
}
.estilo_dias {
  //pon color de fondo azul y color de letra blanco
  background-color: #002e5f;
  color: white;
  padding: 2px;
  text-align: center;
  font-size: 12px;
}
.table {
  //pongo borde
  border-collapse: collapse;
  border: 1px solid #000000;
  width: 100%;
}
.table th {
  border: 1px solid #000000;
  padding: 4px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
.table td {
  border: 1px solid #000000;
  padding: 2px;
  text-align: center;
  font-size: 12px;
}
</style>
